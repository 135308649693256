.booking-confirmation {
      /* background-color: #f9f9f9; */
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
      margin-top: 0px;
}
.inner {
      padding: 30px 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 10px;
}

.top-content-name {
      display: grid;
      justify-content: end;
}
.booking-details {
      border-top: 2px solid #ddd;
      border-bottom: 2px solid #ddd;
      display: flex;
      align-items: center;
}

.booking-ticket-width {
      width: 25%;
}

.booking-ticket {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
}

.hotel-info {
      display: flex;
}

.hotel-info h6 {
      color: #3a2a81;
      font-size: 20px;
}

.check-details {
      display: flex;
      flex-wrap: wrap;
}

.hotel-info-detail-8 {
      width: 66.6667%;
}

.hotel-info-detail-6 {
      width: 50%;
}

.hotel-info-detail-12 {
      width: 100%;
}

.hotel-info-detail-4 {
      width: 33.333%;
}

.check-details p,
.check-details strong {
      font-weight: 500;
}

.check strong {
      font-weight: 510;
}

.badge.booking__confirmed {
      font-size: 14px;
      padding: 8px 10px;
      background-color: #dbfed7;
      color: #3c8625;
      font-weight: 500;
}

a.btn-primary {
      background-color: #ccddee;
      color: #224082;
      border: none;
      padding: 5px 20px;
      font-weight: 510;
}

.hotel-info-details {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
}

.hotel-info-details strong {
      font-weight: 500;
}

.hotel-info-details a {
      text-decoration: none;
      color: black;
}

.time {
      font-size: 18px;
      font-weight: bold;
      margin: 5px;
}

.date {
      font-size: 16px;
      color: #888;
}
.check {
      margin: 5px 0;
}
.reservation-details {
      font-size: 14px;
      color: #444;
}

img {
      border-radius: 5px;
}

.booking-ticket {
      font-size: 14px;
}

/* room details */
.room-details-container {
      padding: 15px;
      margin-top: 20px;
      border-radius: 8px;
      background-color: #f4f4f4;
      border: 1px solid #ccc;
}

.room-card {
      margin-bottom: 15px;
}

.room-info {
      margin-bottom: 10px;
}

.room-info strong {
      font-weight: 510;
}

.room-guests {
      margin-top: 5px;
      display: flex;
      align-items: center;
}

.room-guests strong {
      font-weight: 500;
}

.room-guests svg {
      margin-right: 5px;
}

.meal-plan {
      padding: 5px 0;
      border-top: 2px solid #ddd;
}

.meal-plan strong {
      font-weight: 500;
}

/* policy */
.cancellation-policy-container {
      margin-top: 20px;
      padding-top: 10px;
}

.policy-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
}

.policy-header h4 {
      color: #3a2a81;
      font-size: 18px;
      font-weight: 600;
}

.policy-header hr {
      flex-grow: 1;
      border: none;
      border-top: 2px solid #3a2a81;
      margin-left: 10px;
}

.cancellation-policy-container p {
      font-size: 16px;
      line-height: 1.6;
}

.cancellation-policy-container strong {
      color: #000;
}

/* payment */
.payment-breakup-container {
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-top: 20px;
      padding: 20px;
      background-color: #fff;
      font-family: Arial, sans-serif;
}

.payment-breakup-header {
      color: #3a2a81;
      font-size: 18px;
      margin-bottom: 15px;
}

.payment-details {
      width: 100%;
}

.payment-row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      font-size: 14px;
      color: #333;
}

.payment-row:not(:last-child) {
      border-bottom: 1px solid #ddd;
}

.payment-row .price {
      color: #000;
}

.total-amount {
      font-weight: bold;
}

.price.total {
      font-weight: bold;
      font-size: 16px;
      color: #000;
}
/* information */
.important-info {
      border: 1px solid #e1e1e1;
      padding: 10px;
      border-radius: 5px;
      background-color: #fff8f0;
      margin: 15px 0;
}

.booking-confirmation h4 {
      font-size: 1.2rem;
      color: #a42323;
      font-weight: 510;
      margin-bottom: 10px;
}

.info-box ul {
      list-style: none;
      padding-left: 0;
}

.info-box ul > li {
      margin-bottom: 10px;
}

.info-box strong {
      color: #333;
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
}

.info-box ul ul {
      padding-left: 20px;
}

.info-box ul ul li:before {
      content: "• ";
      color: black;
      font-weight: bold;
}
