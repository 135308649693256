@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800;900&family=Roboto:wght@400;500&display=swap");

:root {
      --border_color: #e6e6e6;
      --link_color: #333333;
      --secondary_color: #25252a;
      --icon_color: #224082;
}

* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Poppins", sans-serif;
      /* max-width: 1200px; */
}

.spinner-border {
      border-width: 1px;
}

a {
      text-decoration: none !important;
      color: #68798b;
}

a:hover {
      color: #224082;
}

.dashboard {
      position: relative;
}

.sidebar {
      max-width: 250px;
      position: fixed;
      width: 250px;
      overflow-y: auto;
      height: 100vh;
}

header ::-webkit-scrollbar {
      width: 5px;
}

header ::-webkit-scrollbar-thumb {
      background: #828bb2;
      border-radius: 10px;
}

header ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
}

.sidebar_header {
      border-bottom: 1px solid var(--border_color);
      padding: 10px 20px;
      background: #fff;
      position: fixed;
      width: 250px;
      z-index: 1;
      text-align: center;
}

.sidebar_header img {
      /* padding: 30px; */
      max-width: 180px;
      height: 45px;
}

.sidebar ul,
li {
      padding-left: 0;
}

.menu_separator {
      color: #68798b;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 700;
      font-size: 12px;
      font-weight: 400;
      opacity: 0.7;
      display: block;
      margin: 24px 0 3px 15px;
}

.menu_bar {
      padding: 4px 7px 4px 28px;
      font-weight: 400;
      color: var(--link_color);
      text-decoration: none;
}

.menu_bar:hover,
.menu_bar:active {
      background: #224082;
      color: #fff;
}

.nav_icon i {
      width: 30px;
      font-size: 20px;
}

.nav_title span {
      text-decoration: none !important;
      font-size: 14px;
}

.nav_right_icon i {
      margin-right: 0px;
      color: var(--secondary_color);
}

/* .menu_bar span{
    line-height: 0;
    padding-right: 10px;
} */
.material-icons,
.material-symbols-outlined {
      /* line-height: 1.5 !important; */
      padding-right: 10px;
      font-size: 20px;
}

/* .metismenu .has-arrow::after {
    transform: rotate(225deg) translate(0, -50%) !important;
}

.metismenu .mm-active>.has-arrow::after, .metismenu .has-arrow[aria-expanded=true]::after {
    transform: rotate(315deg) translate(0, -50%) !important;
} */
.metismenu .has-arrow:after {
      transform: rotate(135deg) translate(0, -50%);
}

#menu li a.mm-active {
      background: #224082;
      color: white;
}

.sub-active.active {
      color: #224082 !important;
      background-color: transparent !important;
}

.sidebar_nav li ul li a.sub-active::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      left: -10px;
      top: 16.5px;
      display: block;
      border-radius: 50%;
      background: #224082;
}

.sidebar_nav li ul li a.sub-active::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background: #224082;
      left: -20px;
      top: 19.5px;
      display: block;
}

.metismenu .mm-active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded="true"]::after {
      transform: rotate(225deg) translate(0, -50%);
}

#sidebar_nav ul li ul {
      border-left: 1px solid var(--base_color);
}

#sidebar_nav ul li ul {
      padding: 0 0 0 20px;
      margin-left: 32px;
      border-left: 1px solid var(--border_color);
}

.sidebar_nav li ul li {
      padding: 4px 10px;
      position: relative;
}

.sidebar_nav li ul li a {
      color: var(--secondary_color);
      font-size: 13px;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 5px;
}

/* main content header css */
.main_content {
      position: absolute;
      top: 0;
      left: 250px;
      width: calc(100% - 250px);
      /* margin-top: 20px; */
      background-color: #f2f2f2;
      min-height: 100vh;
      padding: 20px 20px;
}

.main_header {
      padding: 6px 20px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.175);
      border-radius: 0.375rem;
      margin-bottom: 20px;
}

.header_searchbar input {
      outline: none;
      border: none;
      background: transparent;
}

.main_header span {
      color: var(--link_color);
}

.header_searchbar {
      border-bottom: 1px solid var(--link_color);
      height: 40px;
}

.main_header .header_searchbar span {
      font-size: 17px;
}

.header_function span {
      color: var(--icon_color);
}

.header_link a {
      color: var(--link_color);
}

.slider {
      background-color: #ffffff2b;
      border-radius: 100px;
      padding: 1px;
      margin: 10px;
      cursor: pointer;
      transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      align-items: center;
      position: relative;
      display: inline-block;
      width: 30px;
      height: 20px;
      box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
            rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
            #929693 0px 0px 0px 0px inset,
            rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.slider::after {
      content: "";
      display: flex;
      top: 2.3px;
      left: 2px;
      width: 15px;
      height: 15px;
      background-color: #e3e3e3;
      border-radius: 200px;
      position: absolute;
      box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
      transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
            background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
      will-change: left, background-color;
}

.switch input[type="checkbox"]:checked + .slider {
      box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
            #7d7d7d 0px 0px 0px 2px inset, #8b8c8b 0px 0px 0px 24px inset,
            rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switch input[type="checkbox"]:checked + .slider::after {
      left: 15px;
}

.switch input[type="checkbox"] {
      display: none;
}

/* for dark mode css */
.dark {
      background: #212121;
}

/* Additional Function */
.header_addfunc {
      padding-left: 10px;
}

/* Notification */
.header_notification {
      padding-left: 10px;
}

/* Card */
.panel__content {
      margin-top: 20px;
}

.panel__header {
      background-color: #224082;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 10px;
}

.panel__header span {
      color: white;
}

.panel__body {
      background-color: white;
      padding: 20px;
}

.panel__body input {
      border-radius: 0;
}

.panel__body button {
      padding: 10px 30px;
}

/* header profile css */
/* Menu Profile */
.header_profile {
      margin-left: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 40px;
      position: relative;
}

.header_profile img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      padding: 5px;
}

.header_profile_content {
      position: absolute;
      right: 0;
      top: 48px;
      text-align: right;
      width: 200px;
      border-radius: 5px;
      transition: 0.5s;
      padding: 30px 30px 20px;
      background-color: var(--link_color);
      color: var(--border_color);
      visibility: hidden;
      opacity: 0;
      border-radius: 5px;
}

/* .header_profile_content::before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      top: -14px;
      right: 10px;
      border-width: 0 15px 20px 15px;
      border-color: transparent transparent #415094 transparent;
} */
.header_profile_content p {
      font-size: 12px;
}

.header_profile_details {
      margin-top: 20px;
      border-top: 1px solid #79838b;
      padding-top: 10px;
}

.header_profile_details a {
      color: var(--border_color);
      font-size: 13px;
      display: inline-block;
}

.header_profile_details span {
      color: var(--border_color);
      line-height: 1;
      padding-right: 0px;
      margin: 8px 0;
      margin-left: 10px;
      font-size: 14px;
}

.header_profile:hover .header_profile_content {
      visibility: visible;
      opacity: 1;
      z-index: 999;
}

/* /dropdown menu css */
.dropdown-toggle::after {
      content: none;
}

.header_notification .dropdown .btn {
      background-color: transparent;
      border: none;
}

.header_notification span {
      font-size: 25px;
}

.dropdown-menu {
      width: 350px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: ease 0.3s;
      border: none;
}

button:focus:not(:focus-visible) {
      outline: 0;
}

/* .btn-check:checked+.btn,
.btn.active,
.btn:hover,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: transparent;
    border-color: none;
} */
.dropdown_header {
      padding: 20px 25px;
      background: linear-gradient(90deg, #333333 0%, #224082 100%);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
}

.dropdown_header h4 {
      font-size: 20px;
}

.dropdown_content a {
      font-size: 12px;
      color: var(--icon_color) !important;
}

.card-header {
      background-color: #224082;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.card-title {
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 16px;
      font-weight: 500;
}

/* PAGINATION */
.pagination > li > a {
      background: #fafafa;
      color: #666;
}

.pagination.pagination-flat > li > a {
      border-radius: 0 !important;
}

.pagination {
      display: inline-block;
      padding-left: 0;
      margin: 20px 0;
      border-radius: 4px;
}

.pagination > li {
      display: inline;
}

.pagination > li > a,
.pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #224082;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
      z-index: 2;
      color: #23527c;
      background-color: #eee;
      border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #224082;
      border-color: #224082;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
}

.pager {
      padding-left: 0;
      margin: 20px 0;
      text-align: center;
      list-style: none;
}

.pager li {
      display: inline;
}

.pager li > a,
.pager li > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
}

.pager li > a:focus,
.pager li > a:hover {
      text-decoration: none;
      background-color: #eee;
}

.pager .next > a,
.pager .next > span {
      float: right;
}

.pager .previous > a,
.pager .previous > span {
      float: left;
}

.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
}

thead {
      background: rgba(149, 165, 166, 0.3);
      color: rgba(44, 62, 80, 1);
      border-top: 1px solid #bdc3c7;
}

th .c-container {
      margin-top: 12px;
      margin-bottom: 11px;
}

/*
 * Component: Table
 * ----------------
 */
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
      border-top: 1px solid #f4f4f4;
}

.table > thead > tr > th {
      border-bottom: 2px solid #f4f4f4;
}

.table tr td .progress {
      margin-top: 5px;
}

.table-bordered {
      border: 1px solid #f4f4f4;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
      border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
      border-bottom-width: 2px;
}

.table.no-border,
.table.no-border td,
.table.no-border th {
      border: 0;
}

/* .text-center in tables */
table.text-center,
table.text-center td,
table.text-center th {
      text-align: center;
}

.table.align th {
      text-align: left;
}

.table.align td {
      text-align: right;
}

thead {
      background: rgba(149, 165, 166, 0.3);
      color: rgba(44, 62, 80, 1);
      border-top: 1px solid #bdc3c7;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
      padding: 6px;
      vertical-align: middle;
}

.content {
      padding: 15px;
      margin-right: auto;
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px;
}

/* Button */
.btn,
.navbar .navbar-nav > li > a.btn {
      border-radius: 20px;
      /* box-sizing: border-box; */
      border-width: 2px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 18px;
      border-color: #66615b;
      color: #66615b;
      -webkit-transition: all 150ms linear;
      -moz-transition: all 150ms linear;
      -o-transition: all 150ms linear;
      -ms-transition: all 150ms linear;
      transition: all 150ms linear;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.open > .btn.dropdown-toggle,
.navbar .navbar-nav > li > a.btn:hover,
.navbar .navbar-nav > li > a.btn:focus,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn.active,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle {
      background-color: #66615b;
      color: black;
      border-color: #66615b;
}

.btn:hover .caret,
.btn:focus .caret,
.btn:active .caret,
.btn.active .caret,
.open > .btn.dropdown-toggle .caret,
.navbar .navbar-nav > li > a.btn:hover .caret,
.navbar .navbar-nav > li > a.btn:focus .caret,
.navbar .navbar-nav > li > a.btn:active .caret,
.navbar .navbar-nav > li > a.btn.active .caret,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active,
.navbar .navbar-nav > li > a.btn.disabled,
.navbar .navbar-nav > li > a.btn.disabled:hover,
.navbar .navbar-nav > li > a.btn.disabled:focus,
.navbar .navbar-nav > li > a.btn.disabled.focus,
.navbar .navbar-nav > li > a.btn.disabled:active,
.navbar .navbar-nav > li > a.btn.disabled.active,
.navbar .navbar-nav > li > a.btn:disabled,
.navbar .navbar-nav > li > a.btn:disabled:hover,
.navbar .navbar-nav > li > a.btn:disabled:focus,
.navbar .navbar-nav > li > a.btn:disabled.focus,
.navbar .navbar-nav > li > a.btn:disabled:active,
.navbar .navbar-nav > li > a.btn:disabled.active,
.navbar .navbar-nav > li > a.btn[disabled],
.navbar .navbar-nav > li > a.btn[disabled]:hover,
.navbar .navbar-nav > li > a.btn[disabled]:focus,
.navbar .navbar-nav > li > a.btn[disabled].focus,
.navbar .navbar-nav > li > a.btn[disabled]:active,
.navbar .navbar-nav > li > a.btn[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn.active {
      background-color: transparent;
      border-color: #66615b;
}

.btn.btn-fill,
.navbar .navbar-nav > li > a.btn.btn-fill {
      color: #ffffff;
      background-color: #66615b;
      opacity: 1;
      filter: alpha(opacity=100);
}

.btn.btn-fill:hover,
.btn.btn-fill:focus,
.btn.btn-fill:active,
.btn.btn-fill.active,
.open > .btn.btn-fill.dropdown-toggle,
.navbar .navbar-nav > li > a.btn.btn-fill:hover,
.navbar .navbar-nav > li > a.btn.btn-fill:focus,
.navbar .navbar-nav > li > a.btn.btn-fill:active,
.navbar .navbar-nav > li > a.btn.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn.btn-fill.dropdown-toggle {
      background-color: #403d39;
      color: #ffffff;
      border-color: #403d39;
}

.btn.btn-fill .caret,
.navbar .navbar-nav > li > a.btn.btn-fill .caret {
      border-top-color: #ffffff;
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active,
.btn.btn-simple.active,
.open > .btn.btn-simple.dropdown-toggle,
.navbar .navbar-nav > li > a.btn.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #403d39;
}

.btn.btn-simple .caret,
.navbar .navbar-nav > li > a.btn.btn-simple .caret {
      border-top-color: #ffffff;
}

.btn .caret,
.navbar .navbar-nav > li > a.btn .caret {
      border-top-color: #66615b;
}

.btn:hover,
.btn:focus,
.navbar .navbar-nav > li > a.btn:hover,
.navbar .navbar-nav > li > a.btn:focus {
      outline: 0 !important;
}

.btn:active,
.btn.active,
.open > .btn.dropdown-toggle,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn.active,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0 !important;
}

.btn.btn-icon,
.navbar .navbar-nav > li > a.btn.btn-icon {
      padding: 7px;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
      margin-left: -2px;
}

.navbar .navbar-nav > li > a.btn-primary,
.btn-primary {
      border-color: #007bff;
      color: #007bff;
}

.navbar .navbar-nav > li > a.btn-primary:hover,
.navbar .navbar-nav > li > a.btn-primary:focus,
.navbar .navbar-nav > li > a.btn-primary:active,
.navbar .navbar-nav > li > a.btn-primary.active,
.open > .navbar .navbar-nav > li > a.btn-primary.dropdown-toggle,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
      background-color: #007bff;
      color: #fff;
      border-color: #007bff;
}

.navbar .navbar-nav > li > a.btn-primary:hover .caret,
.navbar .navbar-nav > li > a.btn-primary:focus .caret,
.navbar .navbar-nav > li > a.btn-primary:active .caret,
.navbar .navbar-nav > li > a.btn-primary.active .caret,
.open > .navbar .navbar-nav > li > a.btn-primary.dropdown-toggle .caret,
.btn-primary:hover .caret,
.btn-primary:focus .caret,
.btn-primary:active .caret,
.btn-primary.active .caret,
.open > .btn-primary.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.navbar .navbar-nav > li > a.btn-primary.disabled,
.navbar .navbar-nav > li > a.btn-primary.disabled:hover,
.navbar .navbar-nav > li > a.btn-primary.disabled:focus,
.navbar .navbar-nav > li > a.btn-primary.disabled.focus,
.navbar .navbar-nav > li > a.btn-primary.disabled:active,
.navbar .navbar-nav > li > a.btn-primary.disabled.active,
.navbar .navbar-nav > li > a.btn-primary:disabled,
.navbar .navbar-nav > li > a.btn-primary:disabled:hover,
.navbar .navbar-nav > li > a.btn-primary:disabled:focus,
.navbar .navbar-nav > li > a.btn-primary:disabled.focus,
.navbar .navbar-nav > li > a.btn-primary:disabled:active,
.navbar .navbar-nav > li > a.btn-primary:disabled.active,
.navbar .navbar-nav > li > a.btn-primary[disabled],
.navbar .navbar-nav > li > a.btn-primary[disabled]:hover,
.navbar .navbar-nav > li > a.btn-primary[disabled]:focus,
.navbar .navbar-nav > li > a.btn-primary[disabled].focus,
.navbar .navbar-nav > li > a.btn-primary[disabled]:active,
.navbar .navbar-nav > li > a.btn-primary[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-primary.active,
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
.btn-primary:disabled:active,
.btn-primary:disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
      background-color: transparent;
      border-color: #7a9e9f;
}

.navbar .navbar-nav > li > a.btn-primary.btn-fill,
.btn-primary.btn-fill {
      color: #ffffff;
      background-color: #7a9e9f;
      opacity: 1;
      filter: alpha(opacity=100);
}

.navbar .navbar-nav > li > a.btn-primary.btn-fill:hover,
.navbar .navbar-nav > li > a.btn-primary.btn-fill:focus,
.navbar .navbar-nav > li > a.btn-primary.btn-fill:active,
.navbar .navbar-nav > li > a.btn-primary.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn-primary.btn-fill.dropdown-toggle,
.btn-primary.btn-fill:hover,
.btn-primary.btn-fill:focus,
.btn-primary.btn-fill:active,
.btn-primary.btn-fill.active,
.open > .btn-primary.btn-fill.dropdown-toggle {
      background-color: #427c89;
      color: #ffffff;
      border-color: #427c89;
}

.navbar .navbar-nav > li > a.btn-primary.btn-fill .caret,
.btn-primary.btn-fill .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-primary.btn-simple:hover,
.navbar .navbar-nav > li > a.btn-primary.btn-simple:focus,
.navbar .navbar-nav > li > a.btn-primary.btn-simple:active,
.navbar .navbar-nav > li > a.btn-primary.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn-primary.btn-simple.dropdown-toggle,
.btn-primary.btn-simple:hover,
.btn-primary.btn-simple:focus,
.btn-primary.btn-simple:active,
.btn-primary.btn-simple.active,
.open > .btn-primary.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #427c89;
}

.navbar .navbar-nav > li > a.btn-primary.btn-simple .caret,
.btn-primary.btn-simple .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-primary .caret,
.btn-primary .caret {
      border-top-color: #7a9e9f;
}

.navbar .navbar-nav > li > a.btn-success,
.btn-success {
      border-color: #7ac29a;
      color: #7ac29a;
}

.navbar .navbar-nav > li > a.btn-success:hover,
.navbar .navbar-nav > li > a.btn-success:focus,
.navbar .navbar-nav > li > a.btn-success:active,
.navbar .navbar-nav > li > a.btn-success.active,
.open > .navbar .navbar-nav > li > a.btn-success.dropdown-toggle,
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
      background-color: #7ac29a;
      color: rgba(255, 255, 255, 0.7);
      border-color: #7ac29a;
}

.navbar .navbar-nav > li > a.btn-success:hover .caret,
.navbar .navbar-nav > li > a.btn-success:focus .caret,
.navbar .navbar-nav > li > a.btn-success:active .caret,
.navbar .navbar-nav > li > a.btn-success.active .caret,
.open > .navbar .navbar-nav > li > a.btn-success.dropdown-toggle .caret,
.btn-success:hover .caret,
.btn-success:focus .caret,
.btn-success:active .caret,
.btn-success.active .caret,
.open > .btn-success.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.navbar .navbar-nav > li > a.btn-success.disabled,
.navbar .navbar-nav > li > a.btn-success.disabled:hover,
.navbar .navbar-nav > li > a.btn-success.disabled:focus,
.navbar .navbar-nav > li > a.btn-success.disabled.focus,
.navbar .navbar-nav > li > a.btn-success.disabled:active,
.navbar .navbar-nav > li > a.btn-success.disabled.active,
.navbar .navbar-nav > li > a.btn-success:disabled,
.navbar .navbar-nav > li > a.btn-success:disabled:hover,
.navbar .navbar-nav > li > a.btn-success:disabled:focus,
.navbar .navbar-nav > li > a.btn-success:disabled.focus,
.navbar .navbar-nav > li > a.btn-success:disabled:active,
.navbar .navbar-nav > li > a.btn-success:disabled.active,
.navbar .navbar-nav > li > a.btn-success[disabled],
.navbar .navbar-nav > li > a.btn-success[disabled]:hover,
.navbar .navbar-nav > li > a.btn-success[disabled]:focus,
.navbar .navbar-nav > li > a.btn-success[disabled].focus,
.navbar .navbar-nav > li > a.btn-success[disabled]:active,
.navbar .navbar-nav > li > a.btn-success[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-success.active,
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
      background-color: transparent;
      border-color: #7ac29a;
}

.navbar .navbar-nav > li > a.btn-success.btn-fill,
.btn-success.btn-fill {
      color: #ffffff;
      background-color: #7ac29a;
      opacity: 1;
      filter: alpha(opacity=100);
}

.navbar .navbar-nav > li > a.btn-success.btn-fill:hover,
.navbar .navbar-nav > li > a.btn-success.btn-fill:focus,
.navbar .navbar-nav > li > a.btn-success.btn-fill:active,
.navbar .navbar-nav > li > a.btn-success.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn-success.btn-fill.dropdown-toggle,
.btn-success.btn-fill:hover,
.btn-success.btn-fill:focus,
.btn-success.btn-fill:active,
.btn-success.btn-fill.active,
.open > .btn-success.btn-fill.dropdown-toggle {
      background-color: #42a084;
      color: #ffffff;
      border-color: #42a084;
}

.navbar .navbar-nav > li > a.btn-success.btn-fill .caret,
.btn-success.btn-fill .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-success.btn-simple:hover,
.navbar .navbar-nav > li > a.btn-success.btn-simple:focus,
.navbar .navbar-nav > li > a.btn-success.btn-simple:active,
.navbar .navbar-nav > li > a.btn-success.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn-success.btn-simple.dropdown-toggle,
.btn-success.btn-simple:hover,
.btn-success.btn-simple:focus,
.btn-success.btn-simple:active,
.btn-success.btn-simple.active,
.open > .btn-success.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #42a084;
}

.navbar .navbar-nav > li > a.btn-success.btn-simple .caret,
.btn-success.btn-simple .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-success .caret,
.btn-success .caret {
      border-top-color: #7ac29a;
}

.navbar .navbar-nav > li > a.btn-info,
.btn-info {
      border-color: #17a2b8;
      color: #17a2b8;
}

.navbar .navbar-nav > li > a.btn-info:hover,
.navbar .navbar-nav > li > a.btn-info:focus,
.navbar .navbar-nav > li > a.btn-info:active,
.navbar .navbar-nav > li > a.btn-info.active,
.open > .navbar .navbar-nav > li > a.btn-info.dropdown-toggle,
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
      background-color: #17a2b8;
      color: rgba(255, 255, 255, 0.7);
      border-color: #17a2b8;
}

.navbar .navbar-nav > li > a.btn-info:hover .caret,
.navbar .navbar-nav > li > a.btn-info:focus .caret,
.navbar .navbar-nav > li > a.btn-info:active .caret,
.navbar .navbar-nav > li > a.btn-info.active .caret,
.open > .navbar .navbar-nav > li > a.btn-info.dropdown-toggle .caret,
.btn-info:hover .caret,
.btn-info:focus .caret,
.btn-info:active .caret,
.btn-info.active .caret,
.open > .btn-info.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.navbar .navbar-nav > li > a.btn-info.disabled,
.navbar .navbar-nav > li > a.btn-info.disabled:hover,
.navbar .navbar-nav > li > a.btn-info.disabled:focus,
.navbar .navbar-nav > li > a.btn-info.disabled.focus,
.navbar .navbar-nav > li > a.btn-info.disabled:active,
.navbar .navbar-nav > li > a.btn-info.disabled.active,
.navbar .navbar-nav > li > a.btn-info:disabled,
.navbar .navbar-nav > li > a.btn-info:disabled:hover,
.navbar .navbar-nav > li > a.btn-info:disabled:focus,
.navbar .navbar-nav > li > a.btn-info:disabled.focus,
.navbar .navbar-nav > li > a.btn-info:disabled:active,
.navbar .navbar-nav > li > a.btn-info:disabled.active,
.navbar .navbar-nav > li > a.btn-info[disabled],
.navbar .navbar-nav > li > a.btn-info[disabled]:hover,
.navbar .navbar-nav > li > a.btn-info[disabled]:focus,
.navbar .navbar-nav > li > a.btn-info[disabled].focus,
.navbar .navbar-nav > li > a.btn-info[disabled]:active,
.navbar .navbar-nav > li > a.btn-info[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-info.active,
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
      background-color: transparent;
      border-color: #68b3c8;
}

.navbar .navbar-nav > li > a.btn-info.btn-fill,
.btn-info.btn-fill {
      color: #ffffff;
      background-color: #68b3c8;
      opacity: 1;
      filter: alpha(opacity=100);
}

.navbar .navbar-nav > li > a.btn-info.btn-fill:hover,
.navbar .navbar-nav > li > a.btn-info.btn-fill:focus,
.navbar .navbar-nav > li > a.btn-info.btn-fill:active,
.navbar .navbar-nav > li > a.btn-info.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn-info.btn-fill.dropdown-toggle,
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
      background-color: #3091b2;
      color: #ffffff;
      border-color: #3091b2;
}

.navbar .navbar-nav > li > a.btn-info.btn-fill .caret,
.btn-info.btn-fill .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-info.btn-simple:hover,
.navbar .navbar-nav > li > a.btn-info.btn-simple:focus,
.navbar .navbar-nav > li > a.btn-info.btn-simple:active,
.navbar .navbar-nav > li > a.btn-info.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn-info.btn-simple.dropdown-toggle,
.btn-info.btn-simple:hover,
.btn-info.btn-simple:focus,
.btn-info.btn-simple:active,
.btn-info.btn-simple.active,
.open > .btn-info.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #3091b2;
}

.navbar .navbar-nav > li > a.btn-info.btn-simple .caret,
.btn-info.btn-simple .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-info .caret,
.btn-info .caret {
      border-top-color: #68b3c8;
}

.navbar .navbar-nav > li > a.btn-warning,
.btn-warning {
      border-color: #f3bb45;
      color: #f3bb45;
}

.navbar .navbar-nav > li > a.btn-warning:hover,
.navbar .navbar-nav > li > a.btn-warning:focus,
.navbar .navbar-nav > li > a.btn-warning:active,
.navbar .navbar-nav > li > a.btn-warning.active,
.open > .navbar .navbar-nav > li > a.btn-warning.dropdown-toggle,
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
      background-color: #f3bb45;
      color: rgba(255, 255, 255, 0.7);
      border-color: #f3bb45;
}

.navbar .navbar-nav > li > a.btn-warning:hover .caret,
.navbar .navbar-nav > li > a.btn-warning:focus .caret,
.navbar .navbar-nav > li > a.btn-warning:active .caret,
.navbar .navbar-nav > li > a.btn-warning.active .caret,
.open > .navbar .navbar-nav > li > a.btn-warning.dropdown-toggle .caret,
.btn-warning:hover .caret,
.btn-warning:focus .caret,
.btn-warning:active .caret,
.btn-warning.active .caret,
.open > .btn-warning.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.navbar .navbar-nav > li > a.btn-warning.disabled,
.navbar .navbar-nav > li > a.btn-warning.disabled:hover,
.navbar .navbar-nav > li > a.btn-warning.disabled:focus,
.navbar .navbar-nav > li > a.btn-warning.disabled.focus,
.navbar .navbar-nav > li > a.btn-warning.disabled:active,
.navbar .navbar-nav > li > a.btn-warning.disabled.active,
.navbar .navbar-nav > li > a.btn-warning:disabled,
.navbar .navbar-nav > li > a.btn-warning:disabled:hover,
.navbar .navbar-nav > li > a.btn-warning:disabled:focus,
.navbar .navbar-nav > li > a.btn-warning:disabled.focus,
.navbar .navbar-nav > li > a.btn-warning:disabled:active,
.navbar .navbar-nav > li > a.btn-warning:disabled.active,
.navbar .navbar-nav > li > a.btn-warning[disabled],
.navbar .navbar-nav > li > a.btn-warning[disabled]:hover,
.navbar .navbar-nav > li > a.btn-warning[disabled]:focus,
.navbar .navbar-nav > li > a.btn-warning[disabled].focus,
.navbar .navbar-nav > li > a.btn-warning[disabled]:active,
.navbar .navbar-nav > li > a.btn-warning[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-warning.active,
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
      background-color: transparent;
      border-color: #f3bb45;
}

.navbar .navbar-nav > li > a.btn-warning.btn-fill,
.btn-warning.btn-fill {
      color: #ffffff;
      background-color: #f3bb45;
      opacity: 1;
      filter: alpha(opacity=100);
}

.navbar .navbar-nav > li > a.btn-warning.btn-fill:hover,
.navbar .navbar-nav > li > a.btn-warning.btn-fill:focus,
.navbar .navbar-nav > li > a.btn-warning.btn-fill:active,
.navbar .navbar-nav > li > a.btn-warning.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn-warning.btn-fill.dropdown-toggle,
.btn-warning.btn-fill:hover,
.btn-warning.btn-fill:focus,
.btn-warning.btn-fill:active,
.btn-warning.btn-fill.active,
.open > .btn-warning.btn-fill.dropdown-toggle {
      background-color: #bb992f;
      color: #ffffff;
      border-color: #bb992f;
}

.navbar .navbar-nav > li > a.btn-warning.btn-fill .caret,
.btn-warning.btn-fill .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-warning.btn-simple:hover,
.navbar .navbar-nav > li > a.btn-warning.btn-simple:focus,
.navbar .navbar-nav > li > a.btn-warning.btn-simple:active,
.navbar .navbar-nav > li > a.btn-warning.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn-warning.btn-simple.dropdown-toggle,
.btn-warning.btn-simple:hover,
.btn-warning.btn-simple:focus,
.btn-warning.btn-simple:active,
.btn-warning.btn-simple.active,
.open > .btn-warning.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #bb992f;
}

.navbar .navbar-nav > li > a.btn-warning.btn-simple .caret,
.btn-warning.btn-simple .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-warning .caret,
.btn-warning .caret {
      border-top-color: #f3bb45;
}

.navbar .navbar-nav > li > a.btn-danger,
.btn-danger {
      border-color: #eb5e28;
      color: #eb5e28;
}

.navbar .navbar-nav > li > a.btn-danger:hover,
.navbar .navbar-nav > li > a.btn-danger:focus,
.navbar .navbar-nav > li > a.btn-danger:active,
.navbar .navbar-nav > li > a.btn-danger.active,
.open > .navbar .navbar-nav > li > a.btn-danger.dropdown-toggle,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
      background-color: #eb5e28;
      color: rgba(255, 255, 255, 0.7);
      border-color: #eb5e28;
}

.navbar .navbar-nav > li > a.btn-danger:hover .caret,
.navbar .navbar-nav > li > a.btn-danger:focus .caret,
.navbar .navbar-nav > li > a.btn-danger:active .caret,
.navbar .navbar-nav > li > a.btn-danger.active .caret,
.open > .navbar .navbar-nav > li > a.btn-danger.dropdown-toggle .caret,
.btn-danger:hover .caret,
.btn-danger:focus .caret,
.btn-danger:active .caret,
.btn-danger.active .caret,
.open > .btn-danger.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.navbar .navbar-nav > li > a.btn-danger.disabled,
.navbar .navbar-nav > li > a.btn-danger.disabled:hover,
.navbar .navbar-nav > li > a.btn-danger.disabled:focus,
.navbar .navbar-nav > li > a.btn-danger.disabled.focus,
.navbar .navbar-nav > li > a.btn-danger.disabled:active,
.navbar .navbar-nav > li > a.btn-danger.disabled.active,
.navbar .navbar-nav > li > a.btn-danger:disabled,
.navbar .navbar-nav > li > a.btn-danger:disabled:hover,
.navbar .navbar-nav > li > a.btn-danger:disabled:focus,
.navbar .navbar-nav > li > a.btn-danger:disabled.focus,
.navbar .navbar-nav > li > a.btn-danger:disabled:active,
.navbar .navbar-nav > li > a.btn-danger:disabled.active,
.navbar .navbar-nav > li > a.btn-danger[disabled],
.navbar .navbar-nav > li > a.btn-danger[disabled]:hover,
.navbar .navbar-nav > li > a.btn-danger[disabled]:focus,
.navbar .navbar-nav > li > a.btn-danger[disabled].focus,
.navbar .navbar-nav > li > a.btn-danger[disabled]:active,
.navbar .navbar-nav > li > a.btn-danger[disabled].active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger:hover,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger:focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger.focus,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger:active,
fieldset[disabled] .navbar .navbar-nav > li > a.btn-danger.active,
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
      background-color: transparent;
      border-color: #eb5e28;
}

.navbar .navbar-nav > li > a.btn-danger.btn-fill,
.btn-danger.btn-fill {
      color: #ffffff;
      background-color: #eb5e28;
      opacity: 1;
      filter: alpha(opacity=100);
}

.navbar .navbar-nav > li > a.btn-danger.btn-fill:hover,
.navbar .navbar-nav > li > a.btn-danger.btn-fill:focus,
.navbar .navbar-nav > li > a.btn-danger.btn-fill:active,
.navbar .navbar-nav > li > a.btn-danger.btn-fill.active,
.open > .navbar .navbar-nav > li > a.btn-danger.btn-fill.dropdown-toggle,
.btn-danger.btn-fill:hover,
.btn-danger.btn-fill:focus,
.btn-danger.btn-fill:active,
.btn-danger.btn-fill.active,
.open > .btn-danger.btn-fill.dropdown-toggle {
      background-color: #b33c12;
      color: #ffffff;
      border-color: #b33c12;
}

.navbar .navbar-nav > li > a.btn-danger.btn-fill .caret,
.btn-danger.btn-fill .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-danger.btn-simple:hover,
.navbar .navbar-nav > li > a.btn-danger.btn-simple:focus,
.navbar .navbar-nav > li > a.btn-danger.btn-simple:active,
.navbar .navbar-nav > li > a.btn-danger.btn-simple.active,
.open > .navbar .navbar-nav > li > a.btn-danger.btn-simple.dropdown-toggle,
.btn-danger.btn-simple:hover,
.btn-danger.btn-simple:focus,
.btn-danger.btn-simple:active,
.btn-danger.btn-simple.active,
.open > .btn-danger.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #b33c12;
}

.navbar .navbar-nav > li > a.btn-danger.btn-simple .caret,
.btn-danger.btn-simple .caret {
      border-top-color: #ffffff;
}

.navbar .navbar-nav > li > a.btn-danger .caret,
.btn-danger .caret {
      border-top-color: #eb5e28;
}

.btn-neutral {
      border-color: #ffffff;
      color: #ffffff;
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:active,
.btn-neutral.active,
.open > .btn-neutral.dropdown-toggle {
      background-color: #ffffff;
      color: rgba(255, 255, 255, 0.7);
      border-color: #ffffff;
}

.btn-neutral:hover .caret,
.btn-neutral:focus .caret,
.btn-neutral:active .caret,
.btn-neutral.active .caret,
.open > .btn-neutral.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.7);
}

.btn-neutral.disabled,
.btn-neutral.disabled:hover,
.btn-neutral.disabled:focus,
.btn-neutral.disabled.focus,
.btn-neutral.disabled:active,
.btn-neutral.disabled.active,
.btn-neutral:disabled,
.btn-neutral:disabled:hover,
.btn-neutral:disabled:focus,
.btn-neutral:disabled.focus,
.btn-neutral:disabled:active,
.btn-neutral:disabled.active,
.btn-neutral[disabled],
.btn-neutral[disabled]:hover,
.btn-neutral[disabled]:focus,
.btn-neutral[disabled].focus,
.btn-neutral[disabled]:active,
.btn-neutral[disabled].active,
fieldset[disabled] .btn-neutral,
fieldset[disabled] .btn-neutral:hover,
fieldset[disabled] .btn-neutral:focus,
fieldset[disabled] .btn-neutral.focus,
fieldset[disabled] .btn-neutral:active,
fieldset[disabled] .btn-neutral.active {
      background-color: transparent;
      border-color: #ffffff;
}

.btn-neutral.btn-fill {
      color: #ffffff;
      background-color: #ffffff;
      opacity: 1;
      filter: alpha(opacity=100);
}

.btn-neutral.btn-fill:hover,
.btn-neutral.btn-fill:focus,
.btn-neutral.btn-fill:active,
.btn-neutral.btn-fill.active,
.open > .btn-neutral.btn-fill.dropdown-toggle {
      background-color: #ffffff;
      color: #ffffff;
      border-color: #ffffff;
}

.btn-neutral.btn-fill .caret {
      border-top-color: #ffffff;
}

.btn-neutral.btn-simple:hover,
.btn-neutral.btn-simple:focus,
.btn-neutral.btn-simple:active,
.btn-neutral.btn-simple.active,
.open > .btn-neutral.btn-simple.dropdown-toggle {
      background-color: transparent;
      color: #ffffff;
}

.btn-neutral.btn-simple .caret {
      border-top-color: #ffffff;
}

.btn-neutral .caret {
      border-top-color: #ffffff;
}

.btn-neutral:hover,
.btn-neutral:focus {
      color: #66615b;
}

.btn-neutral:active,
.btn-neutral.active,
.open > .btn-neutral.dropdown-toggle {
      background-color: #ffffff;
      color: #66615b;
}

.btn-neutral.btn-fill {
      color: #66615b;
}

.btn-neutral.btn-fill:hover,
.btn-neutral.btn-fill:focus {
      color: #403d39;
}

.btn-neutral.btn-simple:active,
.btn-neutral.btn-simple.active {
      background-color: transparent;
}

.btn-xs {
      font-size: 12px;
      border-radius: 26px;
      padding: 2px 5px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      overflow: hidden;
      background: #fff;
      background-position: center;
}

#preloader:before {
      /* content: ""; */
      position: fixed;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      border: 6px solid #224082;
      border-top-color: white;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      -webkit-animation: animate-preloader 1s linear infinite;
      animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
      0% {
            transform: rotate(0deg);
      }

      100% {
            transform: rotate(360deg);
      }
}

@keyframes animate-preloader {
      0% {
            transform: rotate(0deg);
      }

      100% {
            transform: rotate(360deg);
      }
}

/* Form */
.price-group,
.company-group,
.user-type,
.user-group,
.student-group,
.general-group,
.student-group1 {
      display: none;
}

.params-panel {
      border: 1px solid #ccc;
      overflow: hidden;
      padding: 15px;
      border-radius: 4px;
}

/* Table */
.badge {
      padding: 5px 10px;
      font-size: 10px;
      background: #00a65a;
}

.well {
      overflow: hidden;
}

.well {
      min-height: 20px;
      padding: 19px;
      margin-bottom: 20px;
      background-color: #f5f5f5;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
      border-color: #ddd;
      border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
      padding: 24px;
      border-radius: 6px;
}

.well-sm {
      padding: 9px;
      border-radius: 3px;
}

/* Summernote  */
.note-btn {
      padding: 2px 10px !important;
      border: 1px solid;
}

.note-editor {
      padding: 0 !important;
}

.note-editor .note-toolbar,
.note-popover .popover-content {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.note-editor.note-airframe .note-editing-area .note-editable,
.note-editor.note-frame .note-editing-area .note-editable {
      background: white;
}

.note-editor .note-toolbar,
.note-popover .popover-content {
      background-color: white;
}

/* DropDown */
.product__menu {
      padding: 0;
      max-width: max-content;
}

.product__menu > li > a {
      color: #66615b;
      font-size: 14px;
      padding: 10px 15px;
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      -ms-transition: none;
      transition: none;
      display: block;
}

.product__menu > li > a:hover {
      background: #224082;
}

.product__menu > li:first-child > a {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
}

.dropdown .product__menu li {
      border-bottom: 1px solid #ccc;
}

/* Navbar Tab */
.nav-tabs {
      width: 100%;
      background: #f2f2f2;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
}

.nav-item {
      border-right: 1px solid;
}

/* Dashboard */
.card-animate {
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
}

.card-animate:hover {
      -webkit-transform: translateY(calc(-1.5rem / 5));
      transform: translateY(calc(-1.5rem / 5));
      -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
      box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
      border-color: #224082;
}

.avatar-sm {
      height: 3rem;
      width: 3rem;
}

.avatar-title {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #224082;
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-weight: 500;
      height: 100%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus,
.form-select:focus,
.form-control:focus,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
      box-shadow: none;
}
